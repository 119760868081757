<template>
 <div>
  <ul class="centerx">
    <li v-for="(item, index) in tree " :key="item.id" class="mb-4 mt-4">
      <div style="display: none">
        {{mainMenu[index] = selected.includes(item.name) }}
      </div>

      <!-- <sub-menu v-if="item.submenu" :value="item" :wholeList="selected" :disabled="disabled" @wholeListChanged="function (value) { selected=value }" /> -->
      <vs-checkbox v-model="mainMenu[index]" :disabled="disabled" @change="duzenle(index,item.name)" class="fxk">
        <feather-icon class="w-5 h-5 float-left text-align-center" :icon="item.icon" /> 
        <span class="ml-2 float-left">{{$t(item.i18n)}}</span>
      </vs-checkbox>
      <div></div>
    </li>
  </ul>
 </div>
</template>
<script>
// import SubMenu from './SubMenu'
export default {
  components:{
    // SubMenu
  },
  props:{
    value:{ default :() => { return [] }, type:Array, required : true},
    tree:{ default :() => { return [] }, type:Array, required : true},
    disabled:{ default :() => { return false }, type:Boolean, required : false}
  },
  computed:{
    selected:{
      get () { return this.value },
      set (newValue) {
        // for (let i = 0; i < this.tree.length ; i++) {
        //   this.mainMenu[i]=false;
        //   if (this.mainMenu[i] ) this.mainMenu[i] = newValue.includes(this.tree[i].name)
        // }
        this.$emit('input', newValue)
      }
    }
  },
  methods : {
    duzenle (index, name) {
      if (this.mainMenu[index] === true && !this.selected.includes(name)) {
        this.selected.push(name)
      } else this.selected = this.selected.filter(function (value) { return value !== name })
    }
  },
  data () {
    return {
      mainMenu:[]
    }
  }
}
</script>
